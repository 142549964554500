<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('CustomerSettingsDatabaseTypeCreate')"
    @edit="(id) => onEdit('CustomerSettingsDatabaseTypeEdit', id)"
  >
    <template v-slot:imageFile="{ row }">
      <a
        v-if="row.imageFile"
        class="editable-list__link"
        @click="onDownloadFile(row.id)"
      >
        {{ row.imageFile.fileName }}
      </a>
    </template>
  </sm-editable-list>
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('editableList');
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'CustomerSettingsDatabaseTypes',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'CustomerSettingsDatabaseTypes',
      tableCaption: 'Виды БД',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Виды БД',
        },
      ],
      tableHeaders: [
        { text: 'Наименование', alias: 'name', order: 'name' },
        { text: 'Файл', alias: 'imageFile', order: 'imageFile' },
        {
          text: 'Выгрузка разработана',
          alias: 'unloadingComplete',
          order: 'unloadingComplete',
        },
        { text: '', alias: 'actions', order: 'actions' },
      ],
    };
  },

  methods: {
    ...mapActions(['downloadFile']),

    onDownloadFile(id) {
      this.downloadFile({
        name: this.controllerName,
        id,
        type: 'file',
      });
    },
  },
};
</script>
